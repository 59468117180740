import { cloneDeep, isObject } from 'lodash';
// We've tested JSONForm schemas with various array sizes for oneOf, allOf, anyOf in order to come up with this threshold
// This is a decent limit which should prevent us from running into the issue here https://github.com/ajv-validator/ajv/issues/1705
const THRESHOLD = 300;

const filterLongStarOfArrays = (schemaToFilter) => {
    const schema = cloneDeep(schemaToFilter);

    // If the schema is not an object or is null, return it as is
    if (!isObject(schema)) {
        return schema;
    }

    // If the schema has an array property (oneOf, allOf, anyOf) and its length is higher than THRESHOLD, then remove it
    ['oneOf', 'allOf', 'anyOf'].forEach((arrayProp) => {
        if (Array.isArray(schema[arrayProp]) && schema[arrayProp].length >= THRESHOLD) {
            delete schema[arrayProp];
        }
    });

    // Recursively process each property in the schema
    for (const key in schema) {
        schema[key] = filterLongStarOfArrays(schema[key]);
    }

    return schema;
};

export default filterLongStarOfArrays;
