import { createTheme } from '@mui/material/styles';
import { brandPrimary, statusError } from './colors';

// Copied the theme from:
// https://bitbucket.org/signiant/signiant-frontend-core/src/ff6e9378d0bfbed1cdc63477c511c8373bb3a236/src/theme.js#lines-39
const lightTheme = createTheme({
    palette: {
        primary: {
            main: brandPrimary,
        },
        secondary: {
            main: brandPrimary,
            contrastText: brandPrimary,
        },
        error: {
            main: statusError,
        },
    },
});

export default lightTheme;
