import { isEmpty } from 'lodash';
import { useState, useEffect, useCallback } from 'react';
import { JsonForms } from '@jsonforms/react';
import Grid from '@mui/material/Grid';
import LoadingButton from '@mui/lab/LoadingButton';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import { materialCells, materialRenderers } from '@jsonforms/material-renderers';
import { constants as platformCommonConstants } from '@signiant/platform-common';
import { createAjv } from '@jsonforms/core';
import filterLongStarOfArrays from './filterLongStarOfArrays';
import { ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import lightTheme from './lightTheme';
import darkTheme from './darkTheme';

// Putting this in the config causes webpack issues and requires modifying its config, which leads to likely having to
// eject this app out of "Create React App". It has to do with the 'config' module needing core node.js modules like
// 'fs' and 'path' which are not polyfilled in webpack 5 by default.
// It's much simpler for now to just use a simple constant and delay dealing with such issues.
// See: https://thecodersblog.com/polyfill-node-core-modules-webpack-5#:~:text=In%20case%20of%20create%2Dreact%2Dapp%3A
const signiantSupportLink = 'https://support.signiant.com/hc/en-us/articles/360020531833-Contact-Support';

const {
    formRenderer: {
        formRenderingEventTypes: {
            FORM_DEFINITION_REQUEST,
            FORM_DEFINITION_RESPONSE,
            FORM_SUBMISSION,
            FORM_SUBMISSION_FAILED,
            FORM_DEFINITION_RENDERED_DIMENSIONS,
        },
        renderModes: { DEFAULT },
    },
} = platformCommonConstants;

// The messages coming in must be from any subdomain of mediashuttle.com, signiant.com, or our netlify pattern of deployments.
// It also permits localhost testing over http. This covers dev/stage/prod, so we don't need to have different values
// in config files, but we could split them up later if desired.
const expectedMsOriginRegexPattern =
    /^http(s?):\/\/(.+\.mediashuttle\.com|.+\.signiant\.com|.*--platform\.netlify\.app|localhost)(:\d+)?$/s;

// We need only the subdomain of mediashuttle.com for the purposes in theme condition since media shuttle does not have dark theme.
const expectedMsUrl = /^http(s?):\/\/(.+\.mediashuttle\.com)(:\d+)?/s;

const initialData = {};

const renderers = [
    ...materialRenderers,
    //register custom renderers here
];

const requestJsonForm = () => {
    console.debug('Requesting form definition from parent window.');
    window.parent.postMessage({ type: FORM_DEFINITION_REQUEST }, '*');
};

const App = () => {
    const [data, setData] = useState(initialData);
    const [errors, setErrors] = useState();
    const [jsonFormSchema, setJsonFormSchema] = useState();
    const [renderMode, setRenderMode] = useState(DEFAULT);
    const [submitFailed, setSubmitFailed] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [additionalErrors, setAdditionalErrors] = useState();
    const [filteredJsonFormSchema, setFilteredJsonFormSchema] = useState();

    // Using createAjv as it fixes the issue reported here https://github.com/eclipsesource/jsonforms/issues/2294 .
    // As mentioned by the support team the function overwrites some default properties of the ajv constructor.
    const ajv = createAjv();

    const filterJsonFormSchemaForValidation = (schema) => {
        // We've encountered an issue in the ajv Validation library used by JSON Forms, link here https://github.com/ajv-validator/ajv/issues/1705 .
        // It fails to validate a schema when it contains oneOf, anyOf, or allOf, with >= 300 items under them.
        // Our workaround is to remove these fields from the schema and manually validate the remainder of the schema.
        // We consider the risk of not validating those parts as acceptable.
        // We don’t need to use the above workaround when the form is being previewed.
        // We only need to use it during actual end-user usage of it where they will be filling in data (DEFAULT rendering mode).
        const jsonFormSchemaToFilter = filterLongStarOfArrays(schema);
        setFilteredJsonFormSchema(jsonFormSchemaToFilter);
    };

    const onParentMessage = useCallback(
        (event) => {
            const message = event.data;

            console.debug(`Form renderer got a message from origin [${event.origin}]`, message);

            if (!new RegExp(expectedMsOriginRegexPattern).test(event.origin)) {
                // Don't log here. There seems to be "other" unexpected messages that fly by, not from MS, and if they get
                // logged here, it might cause unwarranted concern.
                return;
            }

            if (message.type === FORM_DEFINITION_RESPONSE) {
                const { schema } = message.data;
                setJsonFormSchema(schema);
                setRenderMode(message.renderMode || DEFAULT);

                if (renderMode === DEFAULT) {
                    filterJsonFormSchemaForValidation(schema);
                }
            }

            if (message.type === FORM_SUBMISSION_FAILED) {
                const errorCode = message.data.errorCode;
                console.warn(`Form submission failed with errorCode [${errorCode}]`);

                // Display the error to the user
                // In the case where the submission doesn't fail, the parent page that bringing in this content as an iframe
                // will close the form frame. We don't have to worry about handling a SUCCESSFUL submission case. If this
                // behaviour ever changes, we'll have to add a FORM_SUBMISSION_SUCCEEDED event and use it to clear submitFailed.
                setSubmitFailed(true);
                setSubmitting(false);
            }
        },
        [renderMode]
    );

    useEffect(() => {
        window.addEventListener('message', onParentMessage);

        requestJsonForm();

        return () => {
            window.removeEventListener('message', onParentMessage);
        };
    }, [onParentMessage]);

    useEffect(() => {
        if (jsonFormSchema) {
            setTimeout(() => {
                const message = { height: document.body.scrollHeight, width: document.body.scrollWidth };

                window.parent.postMessage({ type: FORM_DEFINITION_RENDERED_DIMENSIONS, data: message }, '*');

                // Need a small delay to let the render complete before we grab the height and width. This is probably just
                // giving the JS event loop a chance to finish render before running this function.
                // Initially this was 1, after switching to the manual validation we've increased the timeout
                // otherwise after the form is loaded and validated it can end up out of bounds.
                // Meaning, the user would not be able to scroll to the bottom and top ends of the form making it hard to interact with.
            }, 500);
        }
    }, [jsonFormSchema]);

    const submitData = useCallback(() => {
        console.debug(`Submitting form data: ${JSON.stringify(data, null, 2)}`);

        setSubmitFailed(false);
        setSubmitting(true);
        window.parent.postMessage({ type: FORM_SUBMISSION, data: { ...data } }, '*');
    }, [data]);

    const onSubmit = useCallback(() => {
        if (isEmpty(errors)) {
            submitData();
        } else {
            console.error(`Errors: ${JSON.stringify(errors, null, 2)}`);
        }
    }, [errors, submitData]);

    const performValidation = (data) => {
        const schema = filteredJsonFormSchema || jsonFormSchema;
        const validate = ajv.compile(schema);
        if (!validate(data)) {
            setAdditionalErrors(validate.errors);
        } else {
            setAdditionalErrors([]);
        }
    };

    const isMediaShuttleUrl = expectedMsUrl.test(document.referrer);
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)', { noSsr: true });
    const usersPreferredTheme = prefersDarkMode ? darkTheme : lightTheme;
    const theme = isMediaShuttleUrl ? lightTheme : usersPreferredTheme;
    if (isMediaShuttleUrl) {
        document.getElementsByTagName('html')[0].style.setProperty('color-scheme', 'light');
    }
    return (
        <ThemeProvider theme={theme}>
            <Grid container justifyContent={'center'} spacing={1} sx={{ padding: '1em', width: '100%' }}>
                {
                    // Don't display anything to avoid the users seeing a quick transition from the loading screen to the
                    // rendered form. It'll look like a glitch.
                    jsonFormSchema && (
                        <Grid item xs={10}>
                            {submitFailed && (
                                <Alert severity="error">
                                    Unable to submit form. Check the information and try again, or contact{' '}
                                    <Link href={signiantSupportLink} underline={'none'}>
                                        Customer Support
                                    </Link>
                                    .
                                </Alert>
                            )}
                            <Box sx={{ padding: '1rem', margin: 'auto' }}>
                                <JsonForms
                                    schema={jsonFormSchema}
                                    data={data}
                                    renderers={renderers}
                                    cells={materialCells}
                                    validationMode={'NoValidation'}
                                    onChange={({ errors, data }) => {
                                        setErrors(errors.map((error) => ({ ...error.params })));
                                        setData(data);
                                        // Contrary to the 'NoValidation' validationMode
                                        // DEFAULT rendering mode validates the input as described in README.md
                                        if (renderMode === DEFAULT) {
                                            performValidation(data);
                                        }
                                    }}
                                    additionalErrors={additionalErrors}
                                />
                            </Box>

                            {renderMode === DEFAULT && (
                                <LoadingButton
                                    onClick={onSubmit}
                                    color="primary"
                                    variant="contained"
                                    disabled={!errors || !isEmpty(errors) || !isEmpty(additionalErrors)}
                                    sx={{ marginLeft: 'auto', marginTop: '2em', display: 'block' }}
                                    loading={submitting}
                                >
                                    Submit
                                </LoadingButton>
                            )}
                        </Grid>
                    )
                }
            </Grid>
        </ThemeProvider>
    );
};

export default App;
