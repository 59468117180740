// The Signiant colors used below come from here:
// https://bitbucket.org/signiant/signiant-frontend-core/src/ff6e9378d0bfbed1cdc63477c511c8373bb3a236/src/colors.js#lines-14
//
// We can't import @signiant/frontend-core project due to it using MUI v4 while this project uses MUI v5.
// It makes sense to do this in the future when @signiant/frontend-core supports MUI 5. We can then probably just import
// the whole theme.

export const brandPrimary = '#1a91d0';
export const statusError = '#f44336';
export const brandPrimaryDarkMode = '#3cbef4';
export const darkModeBackground = '#1a1a1a';
