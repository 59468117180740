import { createTheme } from '@mui/material/styles';
import { brandPrimaryDarkMode, darkModeBackground } from './colors';
import { grey } from '@mui/material/colors';

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        background: {
            default: darkModeBackground,
            paper: grey['900'],
        },
        primary: {
            main: brandPrimaryDarkMode,
        },
        secondary: {
            main: brandPrimaryDarkMode,
            contrastText: brandPrimaryDarkMode,
        },
    },
});

export default darkTheme;
